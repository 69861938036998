/* Bnary Search CSS */

.container {
  padding: 20px;
  text-align: center;
}

.bar {
  width: 30px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: lightgrey;
  color: black;
}

.bar.found {
  background-color: green;
  color: white;
}

.bar.low {
  background-color: yellow;
}

.bar.mid {
  background-color: blue;
  color: white;

}

.bar.high {
  background-color: red;
  color: white;
}


.barHeading {
  font-weight: bold;
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.barHeading.low {
  color: yellow;
}

.barHeading.mid {
  color: green;
}

.barHeading.high {
  color: red;
}

.barContainer {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  padding-top: 50px;
  margin-top: 20px !important;
  justify-content: center;
  align-items: flex-end;
}