.cardWrapperFirst {
    position: relative;
    margin: auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
}

/* card wrapper second for graph image */
.cardWrapperSecond {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
}

/* CSS for Characterization part-4 low high image */
.cardWrapperLowHighImage {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
}

.css-card {
    position: relative;
    margin: auto;
    padding: 24px;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    overflow: hidden;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroundImage {
    width: 614px;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

/* CSS for part4 nand image */
.backgroundNandImage {
    width: 975px;
    height: 827px;
    object-fit: cover;
    z-index: 1;
}

/* css for graph image & low/high */
.backgroundFigureImage,
.lowGraphImage,
.highGraphImage {
    width: 614px;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

/* CSS for graph image low/high part-4 */
.lowGraphImagepart4,
.highGraphImagepart4 {
    width: 90%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.offbtn1,
.onbtn1,
.offbtn2,
.onbtn2,
.offbtn3,
.onbtn3,
.offbtn4,
.onbtn4,
.offbtn5,
.onbtn5,
.offbtn6,
.onbtn6 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

/* CSS For Nand Part4 off and on button */
.offbtn11,
.onbtn11,
.offbtn12,
.onbtn12,
.offbtn13,
.onbtn13,
.offbtn14,
.onbtn14,
.offbtn15,
.onbtn15,
.offbtn16,
.onbtn16 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

/* Box Button */

.offbtn1 {
    top: 57px;
    left: 67px;
}

.onbtn1 {
    top: 57px;
    left: 67px;
}

.offbtn2 {
    top: 82px;
    left: 127px;
}

.onbtn2 {
    top: 82px;
    left: 127px;
}

.offbtn3 {
    top: 82px;
    left: 164px;
}

.onbtn3 {
    top: 82px;
    left: 164px;
}

.offbtn4 {
    top: 82px;
    left: 201px;
}

.onbtn4 {
    top: 82px;
    left: 201px;
}

.offbtn5 {
    top: 76px;
    right: 7px;
}

.onbtn5 {
    top: 76px;
    right: 7px;
}

.offbtn6 {
    top: 76px;
    right: 44px;
}

.onbtn6 {
    top: 76px;
    right: 44px;
}


/* Box Button for Part4 Nand images */

.offbtn11 {
    top: 174px;
    left: 188px;
}

.onbtn11 {
    top: 174px;
    left: 188px;
}

.offbtn12 {
    top: 186px;
    left: 326px;
}

.onbtn12 {
    top: 186px;
    left: 326px;
}

.offbtn13 {
    top: 191px;
    left: 379px;
}

.onbtn13 {
    top: 191px;
    left: 379px;
}

.offbtn14 {
    top: 196px;
    left: 436px;
}

.onbtn14 {
    top: 196px;
    left: 436px;
}

.offbtn15 {
    top: 199px;
    right: 160px;
}

.onbtn15 {
    top: 199px;
    right: 160px;
}

.offbtn16 {
    top: 197px;
    right: 212px;
}

.onbtn16 {
    top: 197px;
    right: 212px;
}


/* arrow line */
.arrow {
    position: absolute;
    z-index: 2;
    width: 27px;
    height: 6px;
    border-radius: 50px;
}

/* css for 1st arrow */
.rotate1 {
    background: green;
    transform: rotate(45deg);
    top: 74px;
    left: 14px;
}

.rotate2 {
    background: red;
    transform: rotate(90deg);
    top: 70px;
    left: 20px;
}

/* css for 2nd arrow */
.rotate3 {
    background: red;
    transform: rotate(30deg);
    top: 36px;
    left: 146px;
}

.rotate4 {
    background: green;
    transform: rotate(157deg);
    top: 28px;
    left: 147px;
}

/* css for 3rd arrow */
.rotate5 {
    background: red;
    transform: rotate(30deg);
    top: 35px;
    left: 183px;
}

.rotate6 {
    background: green;
    transform: rotate(165deg);
    top: 27px;
    left: 182px;

}

/* css for 4th arrow */
.rotate7 {
    background: green;
    transform: rotate(30deg);
    top: 34px;
    left: 212px;
}

.rotate8 {
    background: red;
    transform: rotate(162deg);
    top: 26px;
    left: 213px;
}

/* css for 5th arrow */
.rotate9 {
    background: red;
    transform: rotate(19deg);
    top: 37px;
    right: 10px;
}

.rotate10 {
    background: green;
    transform: rotate(153deg);
    top: 29px;
    right: 11px;
}

/* css for 6th arrow */
.rotate11 {
    background: red;
    transform: rotate(14deg);
    top: 36px;
    right: 45px;
}

.rotate12 {
    background: green;
    transform: rotate(157deg);
    top: 30px;
    right: 46px;
}

/* Characterization part 4 rotate div element */

/* arrow line */
.arrowDivEle {
    position: absolute;
    z-index: 2;
    width: 33px;
    height: 6px;
    border-radius: 50px;
}

/* css for 1st arrow */
.rotate11 {
    background: green;
    transform: rotate(45deg);
    top: 189px;
    left: 127px;
}

.rotate12 {
    background: red;
    transform: rotate(182deg);
    top: 178px;
    left: 125px;
}

/* css for 2nd arrow */
.rotate13 {
    background: red;
    transform: rotate(16deg);
    top: 137px;
    left: 348px;
}

.rotate14 {
    background: green;
    transform: rotate(149deg);
    top: 125px;
    left: 347px;
}

/* css for 3rd arrow */
.rotate15 {
    background: red;
    transform: rotate(15deg);
    top: 135px;
    left: 402px;
}

.rotate16 {
    background: green;
    transform: rotate(150deg);
    top: 125px;
    left: 400px;
}

/* css for 4th arrow */
.rotate17 {
    background: green;
    transform: rotate(6deg);
    top: 135px;
    left: 462px;
}

.rotate18 {
    background: red;
    transform: rotate(119deg);
    top: 124px;
    left: 451px;
}

/* css for 5th arrow */
.rotate19 {
    background: red;
    transform: rotate(4deg);
    top: 139px;
    right: 152px;
}

.rotate20 {
    background: green;
    transform: rotate(140deg);
    top: 129px;
    right: 157px;
}

/* css for 6th arrow */
.rotate21 {
    background: red;
    transform: rotate(5deg);
    top: 139px;
    right: 206px;
}

.rotate22 {
    background: green;
    transform: rotate(150deg);
    top: 129px;
    right: 206px;
}