.csstop {
    position: relative;
    margin: auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    overflow: hidden;
}

.css-card {
    position: relative;
    margin: auto;
    padding: 24px;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    overflow: hidden;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroundImage {
    width: 1024px;
    height: 501px;
    object-fit: cover;
    z-index: 1;
}

.offbtn1,
.onbtn1 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 84px;
    height: 41px;
}

.offbtn2,
.onbtn2 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 84px;
    height: 41px;
}

.offbtn7,
.onbtn7 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 84px;
    height: 41px;
}

.offbtn8,
.onbtn8 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 84px;
    height: 41px;
}

.offbtn9,
.onbtn9 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 84px;
    height: 41px;
}

.offbtn10,
.onbtn10 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 84px;
    height: 41px;
}

.offbtn11,
.onbtn11 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 84px;
    height: 41px;
}

.offbtn12,
.onbtn12 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 84px;
    height: 41px;
}


.offbtn13,
.onbtn13 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 84px;
    height: 41px;
}

.offbtn3,
.onbtn4 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 41px;
}


.offbtn5,
.onbtn6 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 41px;
}


.offbtns2,
.onbtns2 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 41px;
}


.offbtns1,
.onbtns1 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 41px;
}


.offbtns0,
.onbtns0 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 41px;
}

/* Box Button */

.offbtn1 {
    top: 63px;
    left: 119px;
}

.onbtn1 {
    top: 63px;
    left: 119px;
}

.offbtn2 {
    top: 62px;
    left: 222px;
}

.onbtn2 {
    top: 62px;
    left: 222px;
}

.offbtn7 {
    top: 61px;
    left: 330px;
}

.onbtn7 {
    top: 61px;
    left: 330px;
}

.offbtn8 {
    top: 63px;
    left: 433px;
}

.onbtn8 {
    top: 63px;
    left: 433px;
}

.offbtn9 {
    top: 64px;
    right: 399px;
}

.onbtn9 {
    top: 64px;
    right: 399px;
}

.offbtn10 {
    top: 63px;
    right: 296px;
}

.onbtn10 {
    top: 63px;
    right: 296px;
}

.offbtn11 {
    top: 62px;
    right: 188px;
}

.onbtn11 {
    top: 62px;
    right: 188px;
}

.offbtn12 {
    top: 62px;
    right: 86px;
}

.onbtn12 {
    top: 62px;
    right: 86px;
}

.offbtn13 {
    top: 254px;
    right: 6px;
}

.onbtn13 {
    top: 254px;
    right: 6px;
}

/* Circle button left */
.offbtn3 {
    bottom: 205px;
    left: 41px;
}

.onbtn4 {
    bottom: 205px;
    left: 41px;
}


/* Circle button right */
.offbtn5 {
    bottom: 16px;
    left: 185px;
}

.onbtn6 {
    bottom: 16px;
    left: 185px;
}

/* Circle button s2 */
.offbtns2 {
    bottom: 16px;
    left: 399px;
}

.onbtns2 {
    bottom: 16px;
    left: 399px;
}

/* Circle button s1 */
.offbtns1 {
    bottom: 16px;
    right: 376px;
}

.onbtns1 {
    bottom: 16px;
    right: 376px;
}

/* Circle button s0 */
.offbtns0 {
    bottom: 16px;
    right: 164px;
}

.onbtns0 {
    bottom: 16px;
    right: 164px;
}