/* AND Gate css code start here */

.cardWrapperFirstNORGate {
    position: relative;
    margin: auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
}

/* underline text animation css */
.unText {
    display: inline-block;
    position: relative;
}

.unText:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0px;
    height: 2px;
    background-color: #ff0000;
    transition: 300ms;
}

.unText:hover:after {
    width: 100%;
}

.unText:not(:hover):after {
    right: 0;
    left: auto;
}

.titleNORGate {
    position: absolute;
    z-index: 2;
    background-color: #D6E6F2;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleNORGate::before {
    content: 'NOR GATE';
    background-color: #e4d049;
    color: #000;
    font-weight: 700;
    padding: 5px;
    border-radius: 4px;
    margin-right: 5px;
    transition: color 0.3s ease;
    width: 97px;
}

.backgroundImageNORGate {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.instrauctionwrapper {
    width: 82%;
    font-family: monospace;
    transition: padding 0.3s ease, transform 0.3s ease, margin 0.3s ease, box-shadow 0.3s ease;
}

.instrauctionwrapper:hover {
    padding: 20px;
    margin: 40px;
    transform: scale(1.1);
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.btnOffNORgate1 {
    width: 50px;
    height: 46px;
    position: absolute;
    z-index: 2;
    margin: 0;
    padding: 0;
    right: 32.5%;
    top: 8.5%;
    cursor: pointer;
    transition: 0.5s ease;
}

.btnOffNORgate2 {
    width: 50px;
    height: 46px;
    position: absolute;
    z-index: 2;
    margin: 0;
    padding: 0;
    left: 17%;
    top: 45%;
    cursor: pointer;
}

.btnOffNORgate3 {
    width: 50px;
    height: 46px;
    position: absolute;
    z-index: 2;
    margin: 0;
    padding: 0;
    left: 17%;
    top: 68%;
    cursor: pointer;
}

.backgroundImageNORGateBattery {
    position: absolute;
    z-index: 2;
    top: 152px;
    right: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
}

.backgroundImageNORGateLight {
    width: 66px;
    height: 90px;
    position: absolute;
    z-index: 2;
    bottom: 211px;
    right: 347px;
}

/* Add transition for button styles */
.btnOffNORgate1,
.btnOffNORgate2,
.btnOffNORgate3 {
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.btnOffNORgate1:hover,
.btnOffNORgate2:hover,
.btnOffNORgate3:hover {
    background-color: #f0f0f0;
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* ************ NANDGate2 css start from here ************ */

.tablewrappernorgate {
    position: absolute;
    z-index: 2;
    width: 65%;
    height: 56%;
    bottom: 0;
    overflow: auto;
}