@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.aboutImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    object-fit: contain;
    padding-left: 80px;
    padding-right: 80px;
}

.footerlogo {
    padding: 16px;
}

.atag {
    text-decoration: none;
    text-transform: capitalize;
    color: #fff;
}

.signUpText {
    text-decoration: none;
}

.atag:hover {
    transition: all .3s ease;
    color: #0091ff;
}


/* h1 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700 !important;
    font-size: 3.5rem !important;
    line-height: 1.5 !important;
} */

h2 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;
    font-size: 3rem !important;
    line-height: 1.4 !important;
}

h3 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;
    font-size: 2.5rem !important;
    line-height: 1.4 !important;
}

h4 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;
    font-size: 2rem !important;
    line-height: 1.4 !important;
}

h5 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
    line-height: 1.4 !important;
  
}

p {
    font-family: 'Lato', sans-serif !important;
    font-size: 1rem !important;
    line-height: 1.6 !important;
}

button {
    font-family: 'Lato', sans-serif !important;
    font-weight: 600 !important;
    text-transform: none !important;
}
