/* AND Gate css code start here */

.cardWrapperFirstORGate {
    position: relative;
    margin: auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
}

.instrauctionwrapper {
    transition: padding 0.3s ease, transform 0.3s ease, margin 0.3s ease, box-shadow 0.3s ease;
}

.instrauctionwrapper:hover {
    padding: 20px;
    margin: 40px;
    transform: scale(1.1);
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.titleOrGate {
    position: absolute;
    z-index: 2;
    background-color: #D6E6F2;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #F7FBFC; */
}

.titleOrGate::before {
    content: 'OR GATE';
    background-color: #e4d049;
    color: #000;
    font-weight: 700;
    padding: 5px;
    border-radius: 4px;
    margin-right: 5px;
    transition: color 0.3s ease;
    width: 97px;
}

.backgroundImageORGate {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.sub {
    position: absolute;
    z-index: 1;
    top: 200px;
    color: red;
    font-size: 83px;
    right: 227px;
}

.add {
    position: absolute;
    z-index: 1;
    top: 290px;
    color: blue;
    font-size: 53px;
    right: 227px;
}

.backgroundImageORGateBattery {
    position: absolute;
    z-index: 2;
    top: 177px;
    right: 12px;
    transition: transform 0.5s ease;
    cursor: pointer;
}

.backgroundImageORGateLight {
    width: 69px;
    height: 103px;
    position: absolute;
    z-index: 2;
    right: 264px;
    top: 143px;
    transform: rotate(-90deg);
}

.btnOffORgate1 {
    width: 50px;
    height: 46px;
    position: absolute;
    z-index: 2;
    margin: 0;
    padding: 0;
    left: 22%;
    top: 22%;
    cursor: pointer;
    transition: 0.5s ease;
}

.btnOffORgate2 {
    width: 50px;
    height: 46px;
    position: absolute;
    z-index: 2;
    margin: 0;
    padding: 0;
    left: 22%;
    top: 34.5%;
    cursor: pointer;
}

.btnOffORgate3 {
    width: 50px;
    height: 46px;
    position: absolute;
    z-index: 2;
    margin: 0;
    padding: 0;
    left: 21%;
    top: 67.7%;
    cursor: pointer;
}

/* Add transition for button styles */
.btnOffORgate1,
.btnOffORgate2,
.btnOffORgate3 {
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.btnOffORgate1:hover,
.btnOffORgate2:hover,
.btnOffORgate3:hover {
    background-color: #f0f0f0;
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* ************ ORGate2 css start from here ************ */

.tablewrapper {
    position: absolute;
    z-index: 2;
    width: 65%;
    height: 56%;
    bottom: 0;
    overflow: auto;
}