.csstop {
    position: relative;
    margin: auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    overflow: hidden;
}

.css-card {
    position: relative;
    margin: auto;
    padding: 24px;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-radius: 12px;
    margin-top: 20px;
    overflow: hidden;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-image {
    width: 614px;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.offbtn1,
.onbtn1 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.offbtn2,
.onbtn2 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.offbtn3,
.onbtn4 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 41px;
}


.offbtn5,
.onbtn6 {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 41px;
}

/* Box Button */

.offbtn1 {
    top: 0px;
    left: 239px;
}

.onbtn1 {
    top: 0px;
    left: 239px;
}

.offbtn2 {
    top: 0px;
    left: 297px;
}

.onbtn2 {
    top: 0px;
    left: 297px;
}

/* Circle button left */
.offbtn3 {
    bottom: 45px;
    left: 243px;
}

.onbtn4 {
    bottom: 45px;
    left: 243px;
}


/* Circle button right */
.offbtn5 {
    bottom: 45px;
    right: 272px;
}

.onbtn6 {
    bottom: 45px;
    right: 272px;
}