.diagramWrapper {
    display: flex;
    gap: 150px;
    padding-left: 150px;
    margin-top: 30px;
    border: 2px solid grey;
}

.diagram {
    margin-top: 40px;
    position: relative;

}

.binaryInpVal{
    position: absolute;
    font-size: 26px;
    left: -110px;
    border: 2px solid rgb(184, 184, 184);
    padding: 1px 20px;

}

.greyInpVal {
    position: absolute;
    font-size: 26px;
    right: -90px;
    border: 2px solid rgb(184, 184, 184);
    padding: 1px 20px;
}

.circuit {
    margin: 10px auto;
}

.switchbtn {
    position: absolute;
    left: -32px;
    cursor: pointer;
}

.btnB3 {
    top: 0px;
}

.btnB2 {
    top: 94px;
}

.btnB1 {
    top: 210px;
}

.btnB0 {
    top: 325px;
}


.greenlightBtn {
    position: absolute;
    width: 60px;
    right: -16px;
}

.greylightBtn {
    position: absolute;
    width: 60px !important;
    right: 0px;
}

.lightB3 {
    top: -4px;
}

.lightB2 {
    top: 81px;
}

.lightB1 {
    top: 200px;
}

.lightB0 {
    top: 315px;
}

.greylight {
    width: 32px !important;
}

.converterWrapper {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.converter {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
}

.binaryConvertingValBox, .greyConvertingValBox {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8); 
    padding: 10px;
    border-radius: 5px;
   
}

.binaryConvertingValBox {
    top: 48%;
    left: -8%;
    transform: translate(-50%, -50%);
    text-align: center;
    
}

.greyConvertingValBox {
    top: 50%;
    right: -22%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.binaryConvertingVal, .greyConvertingVal {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    border: 2px solid black;
    padding: 6px ;
    margin-bottom: 10px;
}

.decimalBinary, .decimalGrey {
    font-size: 20px;
    margin: 0;
}
