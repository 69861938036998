.wrapper {
    margin-top: 40px;
    border: 2px solid black;
    padding: 20px 50px;
    position: relative;
}

.heading {
    text-align: center !important;
    margin-top: 20px !important;
    font-weight: bold !important;
    min-width: 1200px;
}

.instructionBox {
    position: relative;
}

.instruction {
    position: absolute;
    top: 40px;
    left: 0px;
    background: cyan;
    color: blue;
    padding: 10px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.329);
    z-index: 999;
}

.instruction:hover {
    background: lightblue;
    color: black;
}


.logicGate {
    margin: auto;
}

.diagramWrapper {
    margin: 20px 0px 30px 0px;
    padding: 40px 10px;
    background-color: blueviolet;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    border: 3px solid black;
    border-radius: 4px;
    height: 307px;

}


.inputBox {
    box-sizing: border-box;
    position: absolute;
    padding: 10px 20px;
    outline: none;
    font-weight: bold;
    width: 140px;
}

.inpA {
    top: 109px;
    left: 68px;
}

.inpB {
    top: 171px;
    left: 68px;
}

.bin {
    top: 247px;
    left: 68px;
}

.inpDiff {
    top: 115px;
    right: 74px;
}

.inpBout {
    top: 202px;
    right: 74px;
}

.checkBtn {
    width: 150px;
    position: absolute;
    padding: 10px;
    bottom: 0px;
    left: 430px;
}

.diagramBtn {
    width: 127px;
    position: absolute !important;
    right: 465px !important;
    bottom: 140px !important;
    font-size: 10px !important;
}

.circuitImage {
    max-width: 450px !important;
}

.circuitImage>img {
    width: 100%;
}

.tableWrapper {
    max-width: 550px;
    border-radius: 6px 6px 0px 0px;
}

.tableHeadRow {
    background-color: cadetblue;

}

th {
    color: white !important;
}

td {
    border-top: 2px solid gray !important;
    color: blue !important;
    text-align: center !important;
    font-size: 13px !important;
    font-weight: bolder !important;
}